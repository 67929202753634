import React from "react"
import SummaryContainer from "./SummaryContainer"

class InfoDumpContainer extends React.Component{dataFromParent = {data : this.props.dataFromParent}
    render(){
        return (
            <SummaryContainer dataFromParent = {this.dataFromParent}/>
        );
    }
}



export default InfoDumpContainer