import React, { useEffect, useState } from "react"
import reactDom from "react-dom"

/*class SummaryContainer extends React.Component{
      render(){
        return(
          this.props.dataFromParent.data[0].sectionHeaders.forEach(element => {
            React.createElement('h1',null,"fuck")
            console.log(this.props.dataFromParent.data[0].sectionHeaders[0][0])
          })         
    );
  }
}*/


const SummaryContainer = (props) => {


  const sectionHeaders = Object.values(props.dataFromParent.data[0]['sectionHeaders'][0])
  const renderDataFromParent = () => {

    let parentElems = sectionHeaders.map((header, index) => {
      return (
        <h1 key = {index}>{header}</h1>
      )
    })
    return parentElems
  }

  return (
    <div className = "sectionHeadersDiv">
      {renderDataFromParent()}
    </div>
  )
}
  
/**
 * I want a list here:
 * <ul>
 *  {render my list objects}
 * </ul>
 */

export default SummaryContainer
