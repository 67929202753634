import React from "react"
import ReactDOM from "react-dom"
import { Router } from "react-router";
import { createBrowserHistory } from "history";
// npm install react-router-dom

//Compnents
import MainPageContainer from "./components/MainPageContainer"

//Stylesheet
import "./App.css"
const history = createBrowserHistory();


ReactDOM.render(
<Router history={history}>
    <React.StrictMode>
        <MainPageContainer  />
    </React.StrictMode>
</Router>
, document.getElementById("root"))