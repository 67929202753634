import React from "react"
import InfoDumpContainer from "./InfoDumpContainer"
import InfoHeaderContainer from "./InfoHeaderContainer"

import data from "../data.json"


const jsonData = data.map(x => x)
class MainPageContainer extends React.Component { state = {data : jsonData}
    render() {
        //console.log(this.state.data)
        return(
            <div className = "mainContainer">
                <div className = "topPane">
                    <InfoHeaderContainer dataFromParent = {this.state.data}/>
                </div>
                <div className = "mainPane">
                    <InfoDumpContainer dataFromParent = {this.state.data}/>
                </div>
            </div>
            
        )
    }
}
export default MainPageContainer