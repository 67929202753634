import React from "react"
import ButtonMailto from "./ButtonMailto"


class InfoHeaderContainer extends React.Component{
    render(){
        return (
            <header>
                <h1>{this.props.dataFromParent[0].name}</h1>
                <h2>{this.props.dataFromParent[0].title}</h2>
                <h2>Based in {this.props.dataFromParent[0].address}</h2>
                <h2 className = "mailToButton"><ButtonMailto label = {this.props.dataFromParent[0].email} mailto = {this.props.dataFromParent[0].mailto} /></h2>
                <h2><a href = {this.props.dataFromParent[0].github} target="_blank">GitHub</a> </h2>
            </header>
        );
    }  
}

export default InfoHeaderContainer